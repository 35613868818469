<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 one-click-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="one-click-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_one-click-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          One-click
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_one-click-settings' }"
            ><icon icon="#cx-hea1-edit" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <Draggable
      v-if="activeWidgets && activeWidgets.length"
      v-bind="dragOptions"
      :list="activeWidgets"
      item-key="widgetName"
      tag="div"
      @end="saveOneClickWidgets"
      class="clebex-widget-section"
    >
      <template #item="{element}">
        <component
          v-if="
            element.widgetName !== 'space_passes' ||
              (element.widgetName === 'space_passes' &&
                element.widgetData.length)
          "
          v-bind:is="getComponent(element.widgetName)"
          :data="element.widgetData"
        ></component>
      </template>
    </Draggable>
    <template v-slot:footer>
      <section class="one-click-footer-menu icon-foo1">
        <nav class="menu">
          <ul class="menu-items">
            <li class="menu-item central-item icon-foo2">
              <qr-code-scanner-button />
            </li>
          </ul>
        </nav>
      </section>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import Plan from "@/components/one-click/widgets/Plan";
import Info from "@/components/one-click/widgets/Info";
import Persons from "@/components/one-click/widgets/Persons";
import SpacePasses from "@/components/one-click/widgets/SpacePasses";
import Map from "@/components/one-click/widgets/Map";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import { mapState, mapActions } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OneClick",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Plan,
    Info,
    Persons,
    SpacePasses,
    Map,
    QrCodeScannerButton,
    Draggable: defineAsyncComponent(() => import("vuedraggable"))
  },
  created() {
    this.getOneClickWidgets();
    this.getCompanyPreferences(10);
    if (this.oneClickWidgets) {
      this.setWidgets();
    }
  },
  data() {
    return {
      activeWidgets: [],
      inactiveWidgets: [],
      ordering: 1,
      helpSlug: "one-click"
    };
  },
  computed: {
    ...mapState("oneClick", ["oneClickWidgets"]),
    dragOptions() {
      return {
        animation: 150,
        delay: 1000,
        delayOnTouchOnly: true,
        ghostClass: "clebex-widget-item-wrapper-ghost",
        chosenClass: "clebex-widget-item-wrapper-chosen",
        dragClass: "clebex-widget-item-wrapper-drag"
      };
    }
  },
  methods: {
    ...mapActions("companyPreferences", ["getCompanyPreferences"]),
    ...mapActions("oneClick", ["getOneClickWidgets", "setOneClickPositions"]),
    setWidgets() {
      this.activeWidgets = [];
      this.inactiveWidgets = [];
      if (Object.entries(this.oneClickWidgets)) {
        Object.entries(this.oneClickWidgets).forEach(element => {
          if (element[1].active === 1) {
            this.activeWidgets.push({
              widgetId: element[1].id,
              widgetName: element[0],
              widgetOrdering: element[1].ordering,
              widgetData: element[1].data
            });
          } else {
            this.inactiveWidgets.push({
              widgetId: element[1].id,
              widgetName: element[0],
              widgetOrdering: element[1].ordering,
              widgetData: element[1].data
            });
          }
        });
        this.activeWidgets.sort((a, b) => a.widgetOrdering - b.widgetOrdering);
        this.inactiveWidgets.sort(
          (a, b) => a.widgetOrdering - b.widgetOrdering
        );
      }
    },
    saveOneClickWidgets() {
      this.ordering = 1;
      const postArray = [];
      const fullArray = this.activeWidgets.concat(this.inactiveWidgets);
      fullArray.forEach(element => {
        postArray.push({
          id: element.widgetId,
          ordering: this.ordering
        });
        this.ordering++;
      });
      this.setOneClickPositions(postArray);
    },
    getComponent(name) {
      switch (name) {
        case "plan":
          return "Plan";
        case "space_passes":
          return "SpacePasses";
        case "persons":
          return "Persons";
        case "info":
          return "Info";
        case "map":
          return "Map";
      }
    }
  },
  watch: {
    oneClickWidgets: {
      deep: true,
      handler() {
        this.setWidgets();
      }
    }
  }
};
</script>
